<template>
  <div class="container pb-5">

    <h2 class="pt-4">VRTag: Application Version</h2>

    <div class="py-2">
      This app is a self-updating PWA.<br>
      If the version below differs from your version, restart your app to update.<br>
    </div>

    <div class="py-2">
      App version: {{ this.app.version }}<br> 
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      location: window.location.host,
    }
  }
}
</script>