<template>
  <div class="container pb-5">

    <h2 class="pt-4">Support</h2>

    <p>
      If you are in need of assistance, please follow this checklist first.
    </p>

    <form class="mb-3">
      <div class="form-check mb-3">
        <input class="form-check-input" type="checkbox" id="support_1" value="1" v-model="support">
        <label class="form-check-label" for="support_1">
          Restart the app.<br>
          The app was last started on<br> {{ app.last_started }}.
        </label>
      </div>

      <div class="form-check mb-3">
        <input class="form-check-input" type="checkbox" id="support_2" value="2" v-model="support">
        <label class="form-check-label" for="support_2">
          Log off and on.<br>
          Last logon was on <br> {{ app.storage.get('app.last_login') }}.
        </label>
      </div>

      <div class="form-check mb-3">
        <input class="form-check-input" type="checkbox" id="support_3" value="3" v-model="support">
        <label class="form-check-label" for="support_3">
          Make sure you synchronized.<br>
          The last synchronization was on<br> {{ app.storage.get('sync.downloaded') }}.
        </label>
      </div>
    </form>

    <template v-if="support.sort().join('') == '123'">
      <p class="mt-3">
        If you still require assistance, you can contact support by email.
      </p>
    </template>

    <div class="pt-4 text-center">
      <router-link class="btn btn-sm btn-primary py-2 px-3 me-2 mb-2" to="/debug" replace><i class="fas fa-bug"></i>Debug</router-link>
      <button class="btn btn-sm btn-primary py-2 px-3 me-2 mb-2" @click="this.app.forceUpdateAndRestart()"><i class="fas fa-redo"></i>Restart</button>
      <template v-if="support.sort().join('') == '123'">
        <a href="mailto:support@vrtag.no" class="btn btn-primary mb-2"><i class="far fa-envelope"></i> Email</a>
      </template>
    </div>

  </div>
</template>

<style scoped>
button i.fa, a i.fa, button svg, a svg {
  margin-right: 0.25em;
}
</style>

<script>
export default {
  data() {
    return {
      support: [],
    }
  }
}
</script>