<template>
  <div class="container pb-5">

    <h2 class="pt-4">About VRTag</h2>

    <div class="py-2">
      This app is being developed by StS-ISONOR and Remark ANS.<br>
      More information can be found on the <a href="https://vrtag.no/" target="_blank">website</a>.
    </div>

    <div class="py-2">
      If you have an internet connection, the app will normally update to the latest version automatically.<br>
    </div>
    <div class="py-2">
      You can check the latest version of the app by click the button below.<br>
      This requires an internet connection.<br>
      <button class="btn btn-sm btn-primary mt-3 py-2 px-3 me-2 mb-2" @click="doOpenVersion()"><i class="fas fa-info"></i>Check version</button>
    </div>

    <div class="py-2">
      App: {{ location }}<br>
      App version: {{ this.app.version }}<br> 
      Connected to: {{ this.app.api.server }}
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      location: window.location.host,
    }
  },
  methods: {
    doOpenVersion() {
      var url = 'https://app.vrtag.no/#/version';
      window.open(url);
    }
  }
}
</script>